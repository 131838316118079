import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from 'prop-types'
import Gallery from '../../../plugins/gallery'

const GalleryIsawaonsenDirectionsKofuStop = ({ items }) => {
    const data = useStaticQuery(graphql`
    query {
        allFile(filter: {extension: {regex: "/(jpg)|(png)|(gif)/"}, relativeDirectory: {eq: "railroad/yamanashi/isawaonsen/directions/kofu/stop"}}) {
            edges {
                node {
                    childImageSharp {
                        fluid(maxHeight: 1000, maxWidth: 1000, srcSetBreakpoints: [576, 768, 992, 1200]) {
                            src
                            aspectRatio
                            ...GatsbyImageSharpFluid
                        }
                    }
                    base
                }
            }
        }
    }
  `)

    const edges = data.allFile.edges;

    let images = [];
    let thumbs = [];
    let captions = [];
    items.map(item => {
        const result = edges.filter(function(edge, index) {
            if (item.fileName == edge.node.base) {
                return true;
            }
        });
        images.push(result[0].node.childImageSharp.fluid.src);
        thumbs.push(result[0].node.childImageSharp.fluid);
        captions.push(item.title);
    })

    return (
        <Gallery images={images} thumbs={thumbs} captions={captions} />
        // <pre>{JSON.stringify(result, null, 4)}</pre>
    )
}

GalleryIsawaonsenDirectionsKofuStop.propTypes = {
    items: PropTypes.object.isRequired,
}

export default GalleryIsawaonsenDirectionsKofuStop